<!--
 * @Descripttion : 
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-08-28 10:36:04
 * @LastEditors: min
 * @LastEditTime: 2020-10-22 09:52:35
-->
<template>
  <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
    <div class="logo">
      <template v-if="collapsed">
        <img src="../../assets/logo.png" class="ml_logo_img" alt="">
      </template>
      <template v-else>
        <img src="../../assets/logo.png" class="ml_img" alt="">办证系统
      </template>
    </div>
    <a-menu :defaultSelectedKeys="defaultSelectedKeys" :openKeys="openKeys" mode="inline" :multiple="false" theme="dark"
      :inline-collapsed="collapsed" @openChange="onOpenChange" @click="menuClick">
      <template v-for="item in list">
        <a-menu-item v-if="!item.children" :key="item.path">
          <template v-if="item.icon">
            <a-icon :type="item.icon" />
          </template>
          <span>{{ item.title}}</span>
        </a-menu-item>
        <a-sub-menu v-else :key="item.key">
          <span slot="title">
            <template v-if="item.icon">
              <a-icon :type="item.icon" />
            </template>
            <span>{{ item.title}}</span>
          </span>
          <template v-for="items in item.children">
            <a-menu-item :key="items.path">
              <template v-if="items.icon">
                <a-icon :type="items.icon" />
              </template>
              <span>{{ items.title}}</span>
            </a-menu-item>
          </template>
        </a-sub-menu>
      </template>
    </a-menu>
  </a-layout-sider>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "component-menu",
  props: {
    collapsed: {
      type: Boolean,
      value: false
    }
  },
  data () {
    return {
      list: [],
      selectedKey: [],
      openKey: [],
      // 全部顶级父节点,用来控制所有父级菜单只展开其中的一项，可用遍历菜单信息进行赋值
      rootSubmenuKeys: [],
      // 展开的父菜单项
      openKeys: [],
      // 选中的子菜单项
      defaultSelectedKeys: []
    };
  },
  watch: {
    collapsed (n) {
      console.log(n);
    },
    menuList (n) {
      console.log(n);
    }
  },
  computed: {
    ...mapState({
      menuList: state => state.userStore.menuList
    })
  },
  created () {
    this.defaultSelectedKeys.push(this.$route.path.replace("/", ""));
    this.getMenu();
  },
  methods: {
    // 点击菜单，路由跳转,注意的是当点击MenuItem才会触发此函数
    menuClick ({ item, key, keyPath }) {
      // 获取到当前的key,并且跳转
      if (this.$route.path != "/" + key) {
        this.$router.replace(key);
        console.log({ item, keyPath });
      }
    },
    onOpenChange (openKeys) {
      //  控制只打开一个
      const latestOpenKey = openKeys.find(
        key => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    getMenu () {
      //   获取选中菜单
      const that = this;
      that.list = that.menuList;
      that.rootSubmenuKeys = [];
      that.menuList.forEach(element => {
        if (element.children) {
          //   子菜单
          element.children.forEach(item => {
            if (that.$route.path.replace("/", "") === item.path) {
              that.openKeys.push(element.key);
            }
          });
        }
        that.rootSubmenuKeys.push(element.key);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.logo {
  height: 64px;
  line-height: 64px;
  color: #fff;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  //   margin: 10px 8px;
  font-size: 16px;
  font-weight: bold;

  .ml_img {
    vertical-align: middle;
    display: inline-block;
    margin-right: 10px;
    padding: 0;
    border: 0;
    width: 70px;
  }
  .ml_logo_img {
    width: 70%;
    height: 40px;
    object-fit: contain;
  }
}

.ant-layout-sider {
  overflow-y: auto;
}
</style>