<!--
 * @Descripttion : 公共框架
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-08-27 13:47:44
 * @LastEditors  : min
 * @LastEditTime : 2020-11-05 12:35:58
-->
<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <componentMenu :collapsed="collapsed"></componentMenu>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <a-row>
          <a-col :span="12">
            <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="() => (collapsed = !collapsed)" />
          </a-col>
          <a-col :span="12" style="text-align:right;">
            <a-dropdown style="padding-right:20px;">
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-avatar size="small" icon="user" style="margin-right:10px;" />
                {{nickname}}
                <a-icon type="down" />
              </a>
              <a-menu slot="overlay" @click="onClick">
                <a-menu-item key="1">
                  个人信息
                </a-menu-item>
                <a-menu-item key="2">
                  修改密码
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item key="3">
                  退出
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-col>
        </a-row>
      </a-layout-header>
      <a-layout-content :style="{ padding: '5px 10px', background: '#fff',overflow:'auto',position:'relative' }">
        <!-- 路由跳转 -->
        <a-config-provider :locale="locale">
          <keep-alive include="certificationOrder">
            <router-view />
          </keep-alive>

          <template #renderEmpty>
            <a-empty description="暂无数据" />
          </template>
        </a-config-provider>

      </a-layout-content>
      <a-layout-footer style="textAlign: center">
        办证系统 ©{{new Date().getFullYear()}} Created by CarWins
      </a-layout-footer>
    </a-layout>

    <a-drawer title="修改密码" :width="350" :visible="visibleEdtiPwd" @close="onClose">
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item label="旧密码" prop="oldPwd">
          <a-input-password v-model="form.oldPwd" placeholder="请输入旧密码" />
        </a-form-model-item>
        <a-form-model-item label="新密码" prop="newPwd">
          <a-input-password v-model="form.newPwd" placeholder="请输入新密码" />
        </a-form-model-item>
        <a-form-model-item label="再次输入新密码" prop="checkNewPwd">
          <a-input-password v-model="form.checkNewPwd" placeholder="请再次输入新密码" />
        </a-form-model-item>
      </a-form-model>
      <div class="ml_drawer_footer">
        <a-button @click="onClose">
          取消
        </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="onSubmit">
          确定
        </a-button>
      </div>
    </a-drawer>

    <a-drawer title="个人信息" :width="350" :visible="visibleEdtiUserInfo" @close="onClose">
      <a-form-model ref="ruleFormUserInfo" :model="formUserInfo" :rules="rulesUserInfo">
        <a-form-model-item label="用户名" prop="username">
          <a-input v-model="formUserInfo.username" placeholder="请输入用户名" />
        </a-form-model-item>
        <a-form-model-item label="昵称" prop="nickname">
          <a-input v-model="formUserInfo.nickname" placeholder="请输入昵称" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phone">
          <a-input v-model="formUserInfo.phone" placeholder="请输入手机号" />
        </a-form-model-item>
        <a-form-model-item label="电话" prop="telephone">
          <a-input v-model="formUserInfo.telephone" placeholder="请输入电话" />
        </a-form-model-item>
        <a-form-model-item label="邮箱" prop="email">
          <a-input v-model="formUserInfo.email" placeholder="请输入邮箱" />
        </a-form-model-item>
        <a-form-model-item label="生日" prop="birthday">
          <a-date-picker v-model="formUserInfo.birthday" placeholder="请选择生日" :showToday="false" @change="onChange">
          </a-date-picker>
        </a-form-model-item>
        <a-form-model-item label="性别" prop="sex">
          <a-radio-group name="radioGroup" v-model="formUserInfo.sex">
            <a-radio :value="1">
              男
            </a-radio>
            <a-radio :value="0">
              女
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
      <div class="ml_drawer_footer">
        <a-button @click="onClose">
          取消
        </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="onSubmitUserInfo">
          确定
        </a-button>
      </div>
    </a-drawer>

  </a-layout>
</template>
<script>
import componentMenu from "../components/component-menu/component-menu";
import { mapState } from "vuex";
import { editPwd, editUserInfo } from "../api/users";

import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

import locale from "ant-design-vue/es/locale/zh_CN";

export default {
  components: {
    componentMenu
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (this.form.checkNewPwd !== "") {
          this.$refs.ruleForm.validateField("checkNewPwd");
        }
        callback();
      }
    };

    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.form.newPwd) {
        callback(new Error("两次输入新密码不一致"));
      } else {
        callback();
      }
    };

    return {
      locale,
      collapsed: false,
      visibleEdtiPwd: false, //  是否显示修改密码
      form: {
        oldPwd: "",
        newPwd: "",
        checkNewPwd: ""
      },
      rules: {
        oldPwd: [{ required: true, message: "请输入旧密码" }],
        newPwd: [{ required: true, validator: validatePass }],
        checkNewPwd: [{ required: true, validator: validatePass2 }]
      },

      visibleEdtiUserInfo: false, //  是否显示修改用户信息
      formUserInfo: {
        id: "",
        username: "", //  用户名
        nickname: "", //  昵称
        phone: "", //  手机号
        telephone: "", //  电话
        email: "", //  邮箱
        birthday: "", //  生日
        sex: "" //  性别
      },
      rulesUserInfo: {}
    };
  },
  computed: {
    ...mapState({
      nickname: state => state.userStore.nickname,
      username: state => state.userStore.username,
      phone: state => state.userStore.phone,
      telephone: state => state.userStore.telephone,
      email: state => state.userStore.email,
      birthday: state => state.userStore.birthday,
      sex: state => state.userStore.sex,
      id: state => state.userStore.id
    })
  },
  created() {
    this.formUserInfo.username = this.username; //  用户名
    this.formUserInfo.nickname = this.nickname; //  昵称
    this.formUserInfo.phone = this.phone; //  手机号
    this.formUserInfo.telephone = this.telephone; //  电话
    this.formUserInfo.email = this.email; //  邮箱
    if (this.birthday) {
      this.formUserInfo.birthday = moment(this.birthday, "YYYY-MM-DD"); //  生日
    }

    this.formUserInfo.sex = this.sex; //  性别
    this.formUserInfo.id = this.id; //  用户ID
  },
  methods: {
    onChange(date, dateString) {
      console.log(date);
      this.formUserInfo.birthday = moment(dateString, "YYYY-MM-DD");
    },
    onClick({ key }) {
      const that = this;
      switch (key) {
        case "1":
          that.visibleEdtiUserInfo = true;
          that.$nextTick(() => {
            that.$refs.ruleFormUserInfo.resetFields();
          });
          break;
        case "2":
          that.visibleEdtiPwd = true;
          that.$nextTick(() => {
            that.$refs.ruleForm.resetFields();
          });
          break;
        case "3":
          that.$confirm({
            title: "是否确认退出系统?",
            content: "",
            cancelText: "取消",
            okText: "确定",
            centered: true,
            onOk() {
              localStorage.removeItem("cbz_token");
              that.$router.push({ path: "/" });
            },
            onCancel() { }
          });
          break;
      }
    },
    onSubmit() {
      const that = this;
      that.$refs.ruleForm.validate(valid => {
        if (valid) {
          editPwd(that.username, {
            username: that.username,
            oldPassword: that.form.oldPwd,
            newPassword: that.form.newPwd,
            newPassword2: that.form.checkNewPwd
          }).then(res => {
            console.log(res);
            that.visibleEdtiPwd = false;
            that.$refs.ruleForm.resetFields();
            that.$success({
              okText: "确定",
              content: "修改成功，请重新登录！",
              onOk() {
                localStorage.removeItem("cbz_token");
                that.$router.push("/");
              }
            });
          });
        } else {
          return false;
        }
      });
    },

    onSubmitUserInfo() {
      const that = this;
      editUserInfo({
        id: that.formUserInfo.id,
        username: that.formUserInfo.username,
        nickname: that.formUserInfo.nickname,
        phone: that.formUserInfo.phone,
        telephone: that.formUserInfo.telephone,
        email: that.formUserInfo.email,
        birthday: that.formUserInfo.birthday,
        sex: that.formUserInfo.sex,
        json: true
      }).then(res => {
        that.visibleEdtiUserInfo = false;
        that.$refs.ruleFormUserInfo.resetFields();
        this.$store.dispatch("setUserInfo", res);
      });
    },
    onClose() {
      this.visibleEdtiPwd = false;
      this.visibleEdtiUserInfo = false;
    }
  }
};
</script>
<style lang="scss" >
#components-layout-demo-custom-trigger {
  height: 100vh;

  .trigger {
    font-size: 18px;
    line-height: 40px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: #1890ff;
    }
  }
}

.ant-layout-header {
  box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 9;
  height: 40px;
  line-height: 40px;
}

.ant-layout-footer {
  padding: 10px 50px;
  box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.2);
  color: #797979;
  font-size: 12px;
  z-index: 9;
}

.ant-form-item {
  margin: 0;
}
</style>